import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IMonitoringStore } from './store.types';

export const useMonitoringStore = create<IMonitoringStore>()(immer((set) => ({
  isCollapsed: false,
  setIsCollapsed(value) {
    set(state => {
      state.isCollapsed = value;
    });
  },
})));

import styled from 'styled-components';

export const DatePickStyled = styled.div`
  padding: 10px 24px 20px 24px;

  .rdrDay {
    color: ${({ theme }) => theme.colors.gray[90]};

    &:hover {
      color: ${({ theme }) => theme.colors.blue[50]};

      .rdrDayNumber span {
        color: ${({ theme }) => theme.colors.blue[50]};
      }

      .rdrEndEdge, .rdrStartEdge {
        & ~ .rdrDayNumber {
          span {
            color: ${({ theme }) => theme.colors.gray[0]};
          }
        }
      }
    }

    &.rdrDayHovered {
      &.rdrDayEndOfWeek {
        .rdrInRange {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          right: 0;
        }
      }

      &.rdrDayStartOfWeek {
        .rdrInRange {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          left: 0;
        }
      }

      .rdrDayStartPreview, .rdrDayEndPreview {
        background-color: ${({ theme }) => theme.colors.blue[5]};
      }

      .rdrInRange, .rdrDayInPreview {
        & ~ .rdrDayStartPreview, & ~ .rdrDayEndPreview {
          background-color: ${({ theme }) => theme.colors.blue[10]};
        }
      }
    }

    &.rdrDayEndOfWeek {
      .rdrInRange, .rdrDayInPreview {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        right: 0;
      }
    }

    &.rdrDayStartOfWeek {
      .rdrInRange, .rdrDayInPreview {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        left: 0;
      }
    }

    .rdrInRange, .rdrDayInPreview {
      border: none;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.colors.blue[5]};
    }

    .rdrEndEdge, .rdrStartEdge {
      left: 0;
      right: 0;

      & ~ .rdrDayNumber {
        span {
          color: ${({ theme }) => theme.colors.gray[0]};
        }
      }
    }

    .rdrDayStartPreview, .rdrDayEndPreview {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: none;
    }
  }
`;

export enum ECheckboxPosition {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  position?: ECheckboxPosition;
}
export interface IStyledCheckboxProps {
  $position: ECheckboxPosition;
  $checked?: boolean;
}

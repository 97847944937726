import { FC, useEffect, useMemo } from 'react';
import { useAuthCheck, useUserStore } from 'entities/user';
import { AuthContext } from 'shared/lib/user';
import { LoadingIndicator } from 'shared/ui/loading-indicator';
import { IAuthProviderProps } from './auth-provider.types';

export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const user = useUserStore(state => state.user);
  const { isLoading, isError } = useAuthCheck();
  const userIdFromSession = useMemo(
    () => sessionStorage.getItem('user-id'),
    [],
  );

  useEffect(() => {
    if (user?.id && user.id !== userIdFromSession) {
      sessionStorage.clear();
    }

    if (user?.id) {
      sessionStorage.setItem('user-id', user.id);
    }
  }, [user?.id, userIdFromSession]);

  if (isLoading) {
    return <LoadingIndicator type="full" />;
  }

  if (isError && import.meta.env.DEV) {
    return <div>Please inject credentials for dev auth</div>;
  }

  return (
    <>
      {!!user && (
        <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
      )}
    </>
  );
};

import styled, { RuleSet, css } from 'styled-components';
import { ECheckboxPosition, IStyledCheckboxProps } from './checkbox.types';

const PositionMap: Record<ECheckboxPosition, RuleSet> = {
  [ECheckboxPosition.LEFT]: css`
    .icon {
      order: 0;
    }
  `,
  [ECheckboxPosition.RIGHT]: css`
    .icon {
      order: 1000;
    }
  `,
};

const CheckedMap: Record<number, RuleSet> = {
  NaN: css`
    .icon {
      background-color: ${({ theme }) => theme.colors.blue[50]};

      .check {
        display: none;
      }

      .minus {
        display: block;
      }
    }
  `,
  1: css`
    .icon {
      background-color: ${({ theme }) => theme.colors.blue[50]};

      .check {
        display: block;
      }

      .minus {
        display: none;
      }
    }
  `,
  0: css`
    .icon {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.gray[30]};
      box-sizing: border-box;

      svg {
        display: none;
      }
    }
  `,
};

export const CheckboxStyled = styled.label<IStyledCheckboxProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: inherit;

  .icon {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type='checkbox'] {
    display: none;
    visibility: hidden;
  }

  ${({ $checked }) => CheckedMap[Number($checked)]}
  ${({ $position }) => PositionMap[$position]}
`;

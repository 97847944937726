import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'widgets/header';
import { useMonitoringStore } from 'entities/monitoring';
import { LoadingIndicator } from 'shared/ui/loading-indicator';
import { ModalProvider } from 'shared/ui/modal';
import { MonnitoringLayoutStyled } from './monitoring.styled';

export const MonnitoringLayout: FC = () => {
  const isCollapsed = useMonitoringStore(state => state.isCollapsed);

  return (
    <MonnitoringLayoutStyled $isCollapsed={isCollapsed}>
      {!isCollapsed && <Header />}
      <ModalProvider>
        <Suspense fallback={<LoadingIndicator type="full" />}>
          <Outlet />
        </Suspense>
      </ModalProvider>
    </MonnitoringLayoutStyled>
  );
};
